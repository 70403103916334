<template>
  <v-row>
    <v-col cols="12" sm="12" md="11" lg="11">
      <v-row>
        <v-col cols="12" class="py-0">
          <v-data-table
            :headers="headers"
            :items="item.accounts"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:item.no="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:item.amount="{ item }">
              {{ formatPrice(item.amount) }}
            </template>
            <template v-slot:item.rate="{ item }">
              {{ formatPrice(item.rate) }}
            </template>
            <template v-slot:item.amountIDR="{ item }">
              {{ formatPrice(item.amountIDR) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="12" md="6" xl="2">
          <v-card outlined>
            <v-card-title class="py-0">Total Debit</v-card-title>
            <v-card-text class="text-right mt-3">
              <span>{{ formatPrice(totalDebit) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="6" xl="2">
          <v-card outlined>
            <v-card-title class="py-0">Total Credit</v-card-title>
            <v-card-text class="text-right mt-3">
              <span>{{ formatPrice(totalCredit) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="12" md="1" lg="1">
      <v-row class="py-0 mb-2">
        <v-col cols="12" class="py-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" v-on="on" @click="back">
                <v-icon>
                  mdi-arrow-left
                </v-icon>
              </v-btn>
            </template>
            <span>Back</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "detail-merge-journal",
  props: {
    id: Number,
  },
  computed: {
    totalDebit() {
      let total = 0;
      this.item.accounts.forEach(x => {
        if (x.type === "Debit") {
          total += x.amount;
        }
      });
      return total.toFixed(2);
    },
    totalCredit() {
      let total = 0;
      this.item.accounts.forEach(x => {
        if (x.type === "Credit") {
          total += x.amount;
        }
      });
      return total.toFixed(2);
    },
  },
  data: () => ({
    valid: true,
    item: {},
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account #",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "D/C",
        value: "type",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
      {
        text: "Reference",
        value: "reference",
        sortable: false,
        divider: true,
      },
      {
        text: "Remark",
        value: "remark",
        sortable: false,
        divider: true,
      },
    ],
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    back() {
      this.$router.go(-1);
    },
    async getMergeJournal() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("combinationKasbon/getDetailMergeJournal", this.id)
        .then(response => {
          this.item = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getMergeJournal();
  },
};
</script>

<style></style>
